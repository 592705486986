<script setup lang="ts">
import { toast } from "vue3-toastify";

import type { Modals } from "@/composables/useTaoModals";
import type { DetailInvite } from "@/types";

const isGuest = useIsGuest();
const { add } = useSockets();
const { isDesktop } = useDevice();
const { t } = useT();
const [isShow, toggleSidebar] = useToggle(false);
const { data: appInit } = useAppInitData();
const loginGuard = useLoginGuard();
const route = useRoute();
const { isOpen, open, close } = useTaoModals();
const { openModalOnce, checkDailyOpen } = useWelcomeModal(open, close);
const { logoutUser } = useLogout();
const { isSeasonQuest, refresh: refreshQuestData } = useQuestData();
const { showPwaModal } = usePWA();
const { onMessage, onUpdateToken } = useFirebaseNotification();
const { depositInfoData, refresh: refreshDepositStreak } = useTaoDepositStreak();
const { refresh: refreshCheckRewardsData } = useCheckRewardsData({ immediate: false });
const refCode = useState("refCode");
const { handleOpenGame } = useOpenGame(open);
useGetPageQuestData({ immediate: !isGuest.value, watch: [isGuest] });
const { sendAdAction, sendAdTrigger } = usePopupAds();
const popUpAds = useState("popUpAds");

const isLoggedIn = computed(() => appInit.value?.isGuest === false);
const { isActiveStatus } = usePiggy({ open });
const showPiggyWinGiftLabel = computed(
	() => isLoggedIn.value && isActiveStatus.value && isDesktop && !isOpen("LazyOModalGame")
);
const showDepositStreakLabel = computed(() => appInit.value?.depositStreak?.isActive);
const isEmailFirstContactAndNotConfirmed = computed(
	() => appInit.value?.primaryContact === "email" && !appInit.value?.emailConfirmed
);
const registrationFunnel = computed(() => appInit.value?.Registration?.registrationFunnel);
const isOneClickRegistration = computed(
	() => registrationFunnel.value?.trafficSource === "oneClick" && !registrationFunnel.value?.isPasswordSet
);

const isShowEmailTopline = computed(() => isLoggedIn.value && isEmailFirstContactAndNotConfirmed.value);
const isShowPromoGift = computed(() => isLoggedIn.value && route.path !== "/promotions/");

const handleOpenModal = (modal: string) => {
	open(modal as keyof Modals);
};

const handleDepositPopups = () => {
	if (appInit.value?.depositStreak?.isActive) {
		openModalOnce({
			storage: "showDepositStreakWelcome",
			storageSkip: "showFeatureDepositStreak",
			modal: "LazyOModalDepositStreakWelcome",
			endDate: depositInfoData.value?.finishedAt
		});
		if (!isOpen("LazyOModalDepositStreakWelcome")) {
			checkDailyOpen({ storage: "showFeatureDepositStreak", modal: "LazyOModalDepositStreak" });
		}
		if (route?.query?.openModal === "deposit-streak") {
			open("LazyOModalDepositStreak");
		}
	}
};

watch(isLoggedIn, (value) => {
	setTimeout(() => {
		if (value) {
			refreshQuestData();
		}
	}, 1000);
	if (value) {
		handleDepositPopups();
	}
});

const handleToggleSidebar = () => {
	toggleSidebar();
};

useEvent(["SHOW_VERIFICATION_PHONE"], (event: Event) => {
	const { detail } = event as CustomEvent;
	open("LazyOModalPhoneConfirmation", { location: detail?.location });
});
useEvent(["COMPLETE_PROFILE_REWARD"], (event: Event) => {
	const { detail } = event as CustomEvent<{ rewardEntry: boolean; showRewardsModal: boolean }>;
	if (detail?.rewardEntry) {
		window?.$cash?.$router?.push("/");
	}

	if (detail?.showRewardsModal) {
		open("LazyOModalPhoneConfirmation", { confirmPhoneDeposit: !appInit.value?.phone });
	}

	// TODO here should be reward complete toast
});
useEvent(["LOGOUT_AND_OPEN_AUTH_MODAL"], () => {
	if (window?.$cash?.$store?.getters?.["cash/isOpen"]) {
		window?.$cash?.$router?.push("/");
	}
	logoutUser();
	open("LazyOModalLogin");
});
useEvent(["nuxt:depositStreakRefresh"], () => {
	refreshDepositStreak();
});
useEvent(["nuxt:depositStreakHiW"], () => {
	open("LazyOModalDepositStreakHowItWorks");
});
useEvent(["nuxt:openDepositStreak"], () => {
	open("LazyOModalDepositStreak");
});

useEvent(["nuxt:closeCashbox"], () => {
	sendAdTrigger("closeCashbox");
});

const showToast = (titleText: string, detail: DetailInvite) => {
	toast.success(
		`
			<div class="toast-invite-title">${titleText}</div>
			<div class="toast-invite-content">${t("You earned")}
				<b class="text-chihuahua">${detail.coins ? numberFormat(Number(detail.coins)) : "0"}</b>
				<span>${t("and")}</span>
				<b class="text-coimbatore">${detail.entries ? numberFormat(Number(detail.entries)) : "0"}</b>
			</div>
		`,
		{
			toastId: detail.action,
			icon: h("i", { class: "toast-icon icon-goal" }),
			dangerouslyHTMLString: true,
			theme: toast.THEME.LIGHT,
			position: toast.POSITION.BOTTOM_RIGHT,
			transition: toast.TRANSITIONS.SLIDE,
			autoClose: 5000
		}
	);
};

add("popUpAds", (detail: EventData<PopUpAdData[]>) => {
	if (detail?.data?.length) {
		popUpAds.value = detail.data.sort((modalA, modalB) => modalA.popup_priority - modalB.popup_priority);
	}
});

add("invite", (detail: DetailInvite) => {
	if (detail.action === "friend-qualified") {
		setTimeout(() => {
			showToast(t("Your friend get qualified!"), detail);
		}, 500);
	}
	if (detail.action === "player-qualified") {
		setTimeout(() => {
			showToast(t("You get qualified!"), detail);
		}, 500);
	}
});

onMounted(() => {
	const { refcode } = route.query;

	loginGuard({
		success: () => {
			handleDepositPopups();
		}
	});

	if (refcode) {
		refCode.value = refcode;
		refreshCheckRewardsData();
	}

	if (isOneClickRegistration.value) {
		open("LazyOModalOneClickSignupComplete");
	}

	if (route.query?.action || route.query?.social) {
		dispatchGAEvent({
			event: route.query.action === "register" ? "registration" : "login",
			location: "header",
			type: route.query.social as string,
			step: "success",
			playerId: appInit.value?.login || ""
		});
	}

	if (showPwaModal.value || route.query?.pwa) {
		open("LazyOModalPwaApps");
	}

	pushNotificationsAddEventListener("message", onMessage);
	pushNotificationsAddEventListener("updateToken", onUpdateToken);
});

onUnmounted(() => {
	pushNotificationsRemoveEventListener("message", onMessage);
	pushNotificationsRemoveEventListener("updateToken", onUpdateToken);
});
</script>
<template>
	<div class="layout">
		<ClientOnly>
			<LazyCash v-if="appInit?.isGuest === false" />
			<LazyOCookieControl />
			<ODynamicAdModal :openGame="handleOpenGame" @sendAdAction="sendAdAction" @openModal="handleOpenModal" />
		</ClientOnly>
		<LazyOPromotionGift v-if="isShowPromoGift" />
		<LazyOChallengeSeasonGiftLabel v-if="isSeasonQuest" />
		<LazyOSeasonGiftLabel v-else-if="appInit?.season?.isEnabled" />
		<LazyOPiggybankGiftLabel v-if="showPiggyWinGiftLabel" />
		<div class="header-wrapper">
			<LazyODepositStreakGiftLabel v-if="showDepositStreakLabel" />
			<LazyOEmailNotifyTopline v-if="isShowEmailTopline" />
			<OHeader @toggle-sidebar="handleToggleSidebar" />
		</div>
		<main>
			<OSidebar class="sidebar" :show="isShow" @toggle-sidebar="handleToggleSidebar" />
			<div class="page-content">
				<div class="wrapper gap-6">
					<slot />
				</div>
			</div>
		</main>
		<LazyOMobNavigation v-if="isLoggedIn" @toggle-sidebar="handleToggleSidebar" />
		<OFooter :class="{ 'is-logged': isLoggedIn }" />
		<LazyOMagicBoxesAudio v-if="appInit?.isGuest === false" />
		<LazyONotificationCenterPopup v-if="appInit?.isGuest === false" />
		<ASvgCollection />
	</div>
</template>

<style lang="scss">
.Toastify {
	#friend-qualified,
	#player-qualified {
		width: 328px;
		padding: 16px;
		max-width: 100%;
		margin: auto;

		.toast-invite-title {
			margin-bottom: 2px;
			font-weight: 700;
			font-size: 16px;
			line-height: 21px;
			color: var(--color-gray-50);
			text-transform: uppercase;
		}
		.toast-invite-content {
			line-height: 16px;
			font-size: 12px;

			.text-chihuahua {
				background: url("~/assets/icons/12/coins.svg") left -1px / contain no-repeat;
			}
			.text-coimbatore {
				background: url("~/assets/icons/12/secret-coins.svg") left -1px / contain no-repeat;
			}
			.text-chihuahua,
			.text-coimbatore {
				display: inline-flex;
				font-weight: 700;
				font-size: 12px;
				padding-left: 20px;
				line-height: 16px;
				color: var(--color-gray-50);
			}
		}
		.Toastify {
			&__toast-body {
				display: inline-flex;
				align-items: flex-start;
				gap: 8px;
			}
			&__toast-icon {
				width: 40px;
				height: 40px;
				flex-shrink: 0;
				margin: 0;
			}
			&__close-button {
				display: inline-flex;
				align-items: center;
				justify-content: center;
				opacity: 1;
				width: 20px;
				height: 20px;
				color: var(--color-gray-200);

				svg {
					width: 100%;
					height: 100%;
				}
			}
			&__toast-body {
				white-space: normal;
			}
		}
		.icon-goal {
			width: 100%;
			height: 100%;
			background: url(/public/nuxt-img/referral/quest-money.webp) no-repeat center/cover;
		}
	}
}
</style>

<style lang="scss" scoped>
.sidebar:not(.show) {
	@include media-breakpoint-down(md) {
		display: none;
	}
}
.layout {
	min-height: 100dvh;
	display: flex;
	flex-direction: column;

	@include media-breakpoint-down(lg) {
		footer.is-logged {
			margin-bottom: 56px;
		}
	}
}
.wrapper {
	display: flex;
	flex-direction: column;
	max-width: 1022px;
	width: 100%;
	margin: 0 auto;

	& > *:empty {
		display: none;
	}
}
.header-wrapper {
	display: flex;
	flex-direction: column;
	width: calc(100% - var(--o-sidebar-width));
	margin-left: auto;
	z-index: 13;
	position: sticky;
	left: 0;
	top: -1px;

	@include media-breakpoint-down(lg) {
		width: 100%;
	}
}

main {
	flex: 1;
	margin-left: var(--o-sidebar-width);

	@include media-breakpoint-down(lg) {
		margin-left: 0;
	}
}

.page-content {
	position: relative;
	display: block;
	padding: 24px 16px 16px;

	@include media-breakpoint-up(md) {
		padding-bottom: 32px;
	}
}
</style>
